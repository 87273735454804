import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Image from '../components/image';

const IndexPage = () => (
  <Layout>
    <h1>We've got some big changes coming soon.</h1>
    <p>
      We're coming back with a brand new look. Want us to help you build your
      small business website?
    </p>
    <p>
      <a
        style={{
          background: '#43BADB',
          padding: '16px',
          textDecoration: 'none',
          color: 'white',
          fontSize: '1.4em',
        }}
        href="https://portal.robotfactorial.com/public/form/view/5bf58c67913a2b7540b09ca9"
      >
        Contact us today!
      </a>
    </p>
  </Layout>
);

export default IndexPage;
